<template>
    <div>
        <b-overlay :show="isSavingHousing">
            <b-card>
                <div class="head-create">
                    <h3>Información general</h3>
                    <b-button
                        :to="{ name: 'housing-list'}"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                    >
                        <feather-icon icon="ArrowLeftIcon" style="color: white" />
                    </b-button>
                </div>

                <ValidationObserver ref="createHousing" v-slot="{ invalid }">
                    <b-form @submit.prevent="save">
                        <b-row>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="nombre">
                                    <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="text"
                                        v-model="name"
                                        :state="errors[0] ? false : valid ? true : name != '' ? true : false"

                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="numero de vienda">
                                    <b-form-group
                                    label="Numero de Vivienda"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-input
                                        class="form-control"
                                        type="number"
                                        v-model="numberHousing"
                                        :state="errors[0] ? false : valid ? true : numberHousing != '' ? true : false"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="precio">
                                    <b-form-group label="Precio" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="number"
                                        step="any"
                                        v-model="price"
                                        :state="errors[0] ? false : valid ? true : price != '' ? true : false"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="" name="puntos">
                                    <b-form-group label="Puntos" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="number"
                                        v-model="points"
                                        :state="errors[0] ? false : valid ? true : null"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="" name="porcentaje">
                                    <b-form-group label="Porcentaje" slot-scope="{ valid, errors }">
                                    <b-form-input
                                        class="form-control"
                                        type="number"
                                        step="any"
                                        v-model="percentage"
                                        :state="errors[0] ? false : valid ? true : null"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="required" name="numero de cuartos">
                                    <b-form-group
                                    label="Numero de Cuartos"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <b-form-input
                                        class="form-control"
                                        type="number"
                                        v-model="qtyrooms"
                                        :state="errors[0] ? false : valid ? true : qtyrooms != '' ? true : false"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider name="subcondominio" rules="required">
                                    <b-form-group label="Sub condominio" slot-scope="{ valid, errors }">
                                    <b-form-select
                                        :state="errors[0] ? false : valid ? true : subCondosId != '' ? true : false"
                                        v-model="subCondosId"
                                        @change="setHousingRooms"
                                    >
                                        <option value="">Seleccione sub condominio</option>
                                        <option
                                        v-for="selectSubCondos in subCondos"
                                        :key="selectSubCondos.id"
                                        :value="selectSubCondos.id"
                                        >
                                        {{ selectSubCondos.name }}
                                        </option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider name="Catálogos de habitación" rules="required">
                                    <b-form-group label="Catálogos de habitación" slot-scope="{ valid, errors }">
                                    <b-form-select
                                        :state="errors[0] ? false : valid ? true : housingRoom != '' ? true : false"
                                        v-model="housingRoom"
                                    >
                                        <option value="">Seleccione habitación</option>
                                        <option
                                            v-for="selectHousingRooms in roomsTypes"
                                            :key="selectHousingRooms.id"
                                            :value="selectHousingRooms.id"
                                        >
                                            {{ selectHousingRooms.name }}
                                        </option>
                                    </b-form-select>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="3">
                                <ValidationProvider rules="" name="año de primera remodelación">
                                <b-form-group
                                    label="Año de primera remodelación"
                                    slot-scope="{ valid, errors }"
                                >
                                    <b-form-input
                                        class="form-control"
                                        type="number"
                                        v-model="firstRemodelationYear"
                                        :state="errors[0] ? false : valid ? true : firstRemodelationYear === '' ? true : false"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <div class="float-left pt-2 mt-1">
                                    <b-form-checkbox
                                    v-model="status"
                                    :value="true"
                                    class="custom-control-success"
                                    >
                                    Activado
                                    </b-form-checkbox>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="float-right">
                                    <b-button
                                    type="submit"
                                    class="btn-block"
                                    variant="primary"
                                    :disabled="invalid || isSavingHousing"
                                    >
                                    <b-spinner small v-if="isSavingHousing" /> Guardar
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import { mapState, mapActions, mapMutations} from "vuex";
import { showAlertMessage } from "@/helpers/helpers";
import {  optionsSelectCutoff } from '@/data/fivesClub'

import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple, },
    data() {
        return {
            optionsSelectCutoff,
            name: "",
            numberHousing: "",
            price: "",
            subCondosId: "",
            status: true,
            points: "",
            percentage: "",
            qtyrooms: "",
            optionshousingOwners:"",
            isSavingHousing: false,
            housingRoom: "",
            firstRemodelationYear: null
        };
    },
    computed: {
        ...mapState('fivesClubCatalogs', ['subCondos','housingOwners','requestHousing']),
        ...mapState('auth', ['user']),
        ...mapState('groups', ['roomsTypes']),

    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['saveHousing', 'fetchHousing']),
        ...mapActions('groups', ['fetchRoomsTypes']),
        ...mapMutations('fivesClubCatalogs', ['setHousing']),
        async setHousingRooms(){
            const subcondo = this.subCondos.find(subcondo=> subcondo.id === this.subCondosId)
            if (subcondo){
                await this.fetchRoomsTypes(subcondo.resorts)
            }
        },
        async save() {
            this.isSavingHousing = true;
            const payload = {
                idUser: this.user.idUser,
                name: this.name,
                numberHousing: this.numberHousing,
                price: this.price,
                subCondos: this.subCondosId,
                points: this.points || 0,
                percentage: this.percentage || 0,
                qtyrooms: this.qtyrooms,
                housingRoom: this.housingRoom,
                status: Boolean(this.status),
                firstRemodelationYear: !!this.firstRemodelationYear ? this.firstRemodelationYear : null
            };

            const { status, message } = await this.saveHousing(payload); // saving in backend

            if (status) {
                showAlertMessage("Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right" )
                Object.assign(this.$data, this.$options.data());
                this.$refs.createHousing.reset(); //reset form
                //vuelvo a pedir la info del back
                this.setHousing([])
                if(this.requestHousing){
                    const listHousing = await this.fetchHousing(this.requestHousing)
                    this.setHousing(listHousing)
                }
                this.$router.push({ name: 'housing-list'})
            }

            this.isSavingHousing = false;
        },
    },
};
</script>

<style scoped>
.head-create{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head-create h1{
    font-size: 1.5rem;
    color: #000;
}
</style>